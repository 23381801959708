*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-screen {
  height: 100vh;
}

.h-10 {
  height: 2.5rem;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.flex-col {
  flex-direction: column;
}

.items-stretch {
  align-items: stretch;
}

.gap-4 {
  gap: 1rem;
}

.gap-2 {
  gap: .5rem;
}

.p-6 {
  padding: 1.5rem;
}

.pt-\[27px\] {
  padding-top: 27px;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.italic {
  font-style: italic;
}

.text-sand-12 {
  --tw-text-opacity: 1;
  color: hsl(56 4% 92.8% / var(--tw-text-opacity));
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

*, :after, :before {
  box-sizing: border-box;
}

:root {
  --accent-color: #7671e0;
  --accent-1: #17151f;
  --accent-2: #1c172b;
  --accent-3: #251e40;
  --accent-4: #2c2250;
  --accent-5: #32275f;
  --accent-6: #392c72;
  --accent-7: #443592;
  --accent-8: #5842c3;
  --accent-9: #6e56cf;
  --accent-10: #7c66dc;
  --accent-11: #9e8cfc;
  --accent-12: #f1eefe;
  --accent-A1: #0000;
  --accent-A2: #743afd0e;
  --accent-A3: #7452fe26;
  --accent-A4: #7650ff38;
  --accent-A5: #7654ff49;
  --accent-A6: #7253ff5f;
  --accent-A7: #7053ff83;
  --accent-A8: #6f52ffbb;
  --accent-A9: #8668ffc8;
  --accent-A10: #8e75ffd7;
  --accent-A11: #a18efffa;
  --accent-A12: #f5f2fffa;
  --sand-1: #161615;
  --sand-2: #1c1c1a;
  --sand-3: #232320;
  --sand-4: #282826;
  --sand-5: #2e2e2b;
  --sand-6: #353431;
  --sand-7: #3e3e3a;
  --sand-8: #51504b;
  --sand-9: #717069;
  --sand-10: #7f7e77;
  --sand-11: #a1a09a;
  --sand-12: #ededec;
  --sand-A1: #0000;
  --sand-A2: #fdfdd507;
  --sand-A3: #fefeda0e;
  --sand-A4: #fdfdef14;
  --sand-A5: #fdfde91b;
  --sand-A6: #fdf6e622;
  --sand-A7: #ffffec2c;
  --sand-A8: #fefbea41;
  --sand-A9: #fffcec64;
  --sand-A10: #fffdee73;
  --sand-A11: #fffdf498;
  --sand-A12: #fffffeeb;
  --shadow-color: 60deg 6% 11%;
  --shadow-elevation-medium: 0px -.5px .6px hsl(var(--shadow-color) / .36), 0px -1.6px 1.8px -.8px hsl(var(--shadow-color) / .36), 0px -4px 4.5px -1.7px hsl(var(--shadow-color) / .36), 0px -9.7px 10.9px -2.5px hsl(var(--shadow-color) / .36);
}

h1 {
  color: var(--sand-12);
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  font-family: SF Pro Display, system-ui, -apple-system;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3rem;
}

h2 {
  color: var(--sand-12);
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  font-family: SF Pro Display, system-ui, -apple-system;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.4rem;
}

h3 {
  color: var(--sand-12);
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  font-family: SF Pro Display, system-ui, -apple-system;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2rem;
}

label {
  color: var(--sand-11);
  letter-spacing: .02em;
  -webkit-user-select: none;
  user-select: none;
  font-family: system-ui, -apple-system;
  font-size: .75rem;
  line-height: 16px;
}

body {
  background-color: var(--sand-1);
  margin: 0;
}

button, [role="button"] {
  cursor: default;
}

@media (min-width: 640px) {
  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:w-4\/5 {
    width: 80%;
  }

  .sm\:max-w-\[250px\] {
    max-width: 250px;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:overflow-y-auto {
    overflow-y: auto;
  }

  .sm\:p-8 {
    padding: 2rem;
  }
}

/*# sourceMappingURL=index.685ab666.css.map */
