@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	--accent-color: hsl(243, 64%, 66%);

	--accent-1: hsl(250, 20%, 10.2%);
	--accent-2: hsl(255, 30.3%, 12.9%);
	--accent-3: hsl(253, 37%, 18.4%);
	--accent-4: hsl(252, 40.1%, 22.5%);
	--accent-5: hsl(252, 42.2%, 26.2%);
	--accent-6: hsl(251, 44.3%, 31.1%);
	--accent-7: hsl(250, 46.8%, 38.9%);
	--accent-8: hsl(250, 51.8%, 51.2%);
	--accent-9: hsl(252, 56%, 57.5%);
	--accent-10: hsl(251, 63.2%, 63.2%);
	--accent-11: hsl(250, 95%, 76.8%);
	--accent-12: hsl(252, 87%, 96.4%);

	--accent-A1: hsla(0, 0%, 0%, 0);
	--accent-A2: hsla(258, 98.2%, 61%, 0.054);
	--accent-A3: hsla(252, 98.8%, 65.8%, 0.148);
	--accent-A4: hsla(253, 99.7%, 65.7%, 0.219);
	--accent-A5: hsla(252, 99.7%, 66.4%, 0.286);
	--accent-A6: hsla(251, 99.7%, 66.2%, 0.371);
	--accent-A7: hsla(250, 99.7%, 66.3%, 0.514);
	--accent-A8: hsla(250, 99.7%, 66.1%, 0.733);
	--accent-A9: hsla(252, 99.9%, 70.3%, 0.786);
	--accent-A10: hsla(251, 99.9%, 72.9%, 0.844);
	--accent-A11: hsla(250, 100%, 77.9%, 0.98);
	--accent-A12: hsla(254, 100%, 97.5%, 0.98);

	--sand-1: hsl(61, 2%, 8.3%);
	--sand-2: hsl(60, 3.7%, 10.6%);
	--sand-3: hsl(58, 3.7%, 13.1%);
	--sand-4: hsl(57, 3.6%, 15.3%);
	--sand-5: hsl(56, 3.7%, 17.4%);
	--sand-6: hsl(55, 3.7%, 19.9%);
	--sand-7: hsl(53, 3.7%, 23.6%);
	--sand-8: hsl(50, 3.8%, 30.6%);
	--sand-9: hsl(50, 4%, 42.7%);
	--sand-10: hsl(52, 3.1%, 48.3%);
	--sand-11: hsl(50, 4%, 61.8%);
	--sand-12: hsl(56, 4%, 92.8%);

	--sand-A1: hsla(0, 0%, 0%, 0);
	--sand-A2: hsla(60, 89.8%, 91.4%, 0.026);
	--sand-A3: hsla(60, 95.5%, 92.5%, 0.056);
	--sand-A4: hsla(60, 75.6%, 96.4%, 0.078);
	--sand-A5: hsla(60, 81.9%, 95.2%, 0.104);
	--sand-A6: hsla(41, 87.6%, 94.8%, 0.134);
	--sand-A7: hsla(60, 95.4%, 96.2%, 0.172);
	--sand-A8: hsla(49, 93.5%, 95.7%, 0.254);
	--sand-A9: hsla(52, 97.3%, 96.2%, 0.391);
	--sand-A10: hsla(52, 97.8%, 96.7%, 0.451);
	--sand-A11: hsla(51, 97%, 97.8%, 0.597);
	--sand-A12: hsla(60, 88.7%, 99.8%, 0.923);

	--shadow-color: 60deg 6% 11%;
	--shadow-elevation-medium: 0px -0.5px 0.6px hsl(var(--shadow-color) / 0.36),
		0px -1.6px 1.8px -0.8px hsl(var(--shadow-color) / 0.36),
		0px -4px 4.5px -1.7px hsl(var(--shadow-color) / 0.36),
		0px -9.7px 10.9px -2.5px hsl(var(--shadow-color) / 0.36);
}

h1 {
	color: var(--sand-12);
	font-weight: 600;
	font-size: 1.8rem;
	font-family: "SF Pro Display", system-ui, -apple-system;
	line-height: 3rem;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
}

h2 {
	color: var(--sand-12);
	font-weight: 500;
	font-size: 1.5rem;
	font-family: "SF Pro Display", system-ui, -apple-system;
	line-height: 2.4rem;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
}

h3 {
	color: var(--sand-12);
	font-weight: 500;
	font-size: 1.2rem;
	font-family: "SF Pro Display", system-ui, -apple-system;
	line-height: 2rem;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
}

label {
	color: var(--sand-11);
	font-size: 0.75rem;
	font-family: system-ui, -apple-system;
	letter-spacing: 0.02em;
	line-height: 16px;
	user-select: none;
}

body {
	margin: 0;
	background-color: var(--sand-1);
}

button,
[role="button"] {
	cursor: default;
}
